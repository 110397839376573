
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} LAMPIRAN</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        
        <vs-row class="mt-5">
          <vs-col vs-align="center" vs-w="12" class="pr-2">
            <vs-input label="Lampiran" v-model="dataLampiran" class="w-full" name="item-lampiran" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('item-lampiran')">{{ errors.first('item-lampiran') }}</span>
          </vs-col>
        </vs-row>

        <div class="vx-row mt-5">
          <div class="vx-col w-full">
              <template v-if="dataFileRead">
                <template v-if="upFile">
                  <iframe :src="getPDF(dataFileRead)" height="200" width="100%"></iframe>
                </template>
                <template v-else>
                  <iframe :src="dataFileRead" height="200" width="100%"></iframe>
                </template>
                <div class="modify-img flex justify-between mt-5 mb-5">
                  <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrFile" accept="pdf/*">
                  <vs-button class="w-full" @click="$refs.updateImgInput.click();upFile = false">Update File</vs-button>
                </div>
              </template>

              <div class="upload-img" v-if="!dataFileRead">
                <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrFile" accept="pdf/*">
                <vs-button @click="$refs.uploadImgInput.click(); upFile = false" class="w-full">Upload File</vs-button>
              </div>
          </div>
        </div>

        <!---
        <vs-row class="mt-5">
          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Group"
              v-model="dataGroup"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLampiran"/>
            </vs-select>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Parent"
              v-model="dataParent"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLampiran"/>
            </vs-select>
          </vs-col>
        </vs-row>
        -->

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {

      dataId: null,
      dataLampiran: null,
      dataGroup: 0,
      dataParent: 0,
      
      dataFile : null,
      dataFileRead : null,
      upFile: true,

      listLampiran: [],

      settings: {
        maxScrollbarLength: 80,
        wheelSpeed: .80
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      
      let configs = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.get('/term-list/lampiran', configs)
      .then((response) => {
        let {data} = response.data
        this.listLampiran = data
      })
      .catch((error) => {
        let data = error.data
        this.listLampiran = data
      })

      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, name, term_group, parent, file  } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataLampiran = name
        this.dataGroup = term_group
        this.dataParent = parent
        this.dataFileRead = file
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.dataLampiran && this.dataFile
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {

          let formData = new FormData()
          formData.set('id', this.dataId)
          formData.set('name', this.dataLampiran)
          formData.set('term_group', this.dataGroup)
          formData.set('parent', this.dataParent)

          formData.set('taxonomy', 'lampiran')
      
          if (this.upFile === false) { 
            formData.append('file', this.dataFile)
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch('lampiran/updateFormLampiran', formData)
          } else {
            this.$store.dispatch('lampiran/addLampiran', formData)
          }
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    },
    initValues () {
      if (this.data.id) return
      this.dataId = null
      this.dataLampiran = null
      this.dataGroup = 0
      this.dataParent = 0
      this.dataFile = null
      this.dataFileRead= null
      this.upFile = true
    },
    updateCurrFile (input) {
      this.dataFile = input.target.files[0]
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataFileRead = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
