import axios from '@/axios.js'

const state = () => ({
    lampirans: []
})

const mutations = {
    SET_LAMPIRANS (state, payload) {
      state.lampirans = payload
    },
    ADD_LAMPIRAN (state, item) {
      state.lampirans.unshift(item)
    },
    UPDATE_LAMPIRAN (state, lampiran) {
      const lampiranIndex = state.lampirans.findIndex((p) => p.id === lampiran.id)
      Object.assign(state.lampirans[lampiranIndex], lampiran)
    },
    REMOVE_LAMPIRAN (state, itemId) {
      const ItemIndex = state.lampirans.findIndex((p) => p.id === itemId)
      state.lampirans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchLampiran ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/lampiran`)
        .then((response) => {
          commit('SET_LAMPIRANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addLampiran ({ commit }, lampiran ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', lampiran)
        .then((response) => {
          commit('ADD_LAMPIRAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateLampiran ({ commit }, lampiran) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${lampiran.id}`, lampiran)
        .then((response) => {
            commit('UPDATE_LAMPIRAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormLampiran ({ commit }, lampiran) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, lampiran)
        .then((response) => {
            commit('UPDATE_LAMPIRAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeLampiran ({ commit }, lampiran) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${lampiran.id}`)
        .then((response) => {
            commit('REMOVE_LAMPIRAN', lampiran.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}